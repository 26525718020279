import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, SHORT_LINK } from './types'

export const Shortlink = (name, link, param = '') => async (dispatch) => {

	let url = `shortlink`;
	let payload = { name: name, link: link, param: param }

	dispatch({ type: IS_LOADING, status: true, names: { SHORT_LINK: true } });
	await axios.post(url, payload).then(response => {
		dispatch({ type: SHORT_LINK, name: name, data: response.data.data });
		dispatch({ type: IS_LOADING, status: false, names: { SHORT_LINK: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { SHORT_LINK: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}