import {
	BANNER_LIST,
	BANNER_CLEAR
} from './../actions/types';

const initialState = {
	dataAll: []
};

export default function BannerReducer(state = initialState, action) {

	switch (action.type) {
		case BANNER_LIST:
			return { ...state, ...action.playload };
		case BANNER_CLEAR:
			return { ...state, status: undefined };
		default:
			return { ...state };
	}
};