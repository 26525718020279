import _ from "lodash";
const [, , subdomain] = window.location.hostname.split('.').reverse();

export const MARKETPLACE_URL = process.env.REACT_APP_MARKETPLACE_URL;
export const COOKIE_SECURE = process.env.REACT_APP_COOKIE_SECURE;
export const DEFAULT_CREATE_BY_APP = process.env.REACT_APP_DEFAULT_CREATE_BY_APP;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const APP_URL = process.env.REACT_APP_URL;
export const DEFAULT_SUB_DOMAIN = process.env.REACT_APP_DEFAULT_SUB_DOMAIN;
export const SUB_DOMAIN = _.isUndefined(subdomain) ? DEFAULT_SUB_DOMAIN : subdomain;
export const SHORT_LINK_URL = process.env.REACT_APP_SHORT_LINK_URL;
export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL;
export const PAYMENT_METHOD = process.env.REACT_APP_PAYMENT_METHOD;
export const SIGNAL_HOST = process.env.REACT_APP_SIGNAL_HOST;
export const SIGNAL_PORT = process.env.REACT_APP_SIGNAL_PORT;
export const SIGNAL_PATH = process.env.REACT_APP_SIGNAL_PATH;
export const DOMAIN_SERVER_MEETING = process.env.REACT_APP_DOMAIN_SERVER_MEETING;
export const WAITING_PERIOD_CALL = process.env.REACT_APP_WAITING_PERIOD_CALL;
export const WAITING_REQUEST_CALL_NOW = process.env.REACT_APP_WAITING_REQUEST_CALL_NOW;
export const WAITING_PHARMACY = process.env.REACT_APP_WAITING_PHARMACY;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const LINE_CLIENT_ID = process.env.REACT_APP_LINE_CLIENT_ID;
export const LINE_CLIENT_SECRET = process.env.REACT_APP_LINE_CLIENT_SECRET;
export const LINE_NONCE = process.env.REACT_APP_LINE_NONCE;
export const LINE_REDIRECT_URI = process.env.REACT_APP_LINE_REDIRECT_URI;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
export const APPLE_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI;
export const EKYC_URL = process.env.REACT_APP_EKYC_URL;
export const EKYC_CHANNEL = process.env.REACT_APP_EKYC_CHANNEL;
export const EKYC_CALLBACK_URL = process.env.REACT_APP_EKYC_CALLBACK_URL;
export const API_PARTNER_URL = process.env.REACT_APP_API_PARTNER_URL;
export const API_PARTNER_ACCESS_TOKEN = process.env.REACT_APP_API_PARTNER_ACCESS_TOKEN;
export const USE_EKYC = process.env.REACT_APP_USE_EKYC;
export const EXTEND_TIME = 60 * 1;
export const AMBULANCE_PRICE = process.env.REACT_APP_AMBULANCE_PRICE;