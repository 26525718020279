import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, DRUG_DROP_POINT_LIST, DRUG_CREATE_ORDER, DRUG_CLEAR } from './types'

export const drugDropPointList = (refCode, latitude, longitude) => async (dispatch) => {

	let url = `drug/dropPoint?refCode=${refCode}&lat=${latitude}&lng=${longitude}`;
	let columns = ['name', 'deliverChannel', 'distance', 'location', 'serviceStart', 'serviceEnd', 'shop_image'];
	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { DRUG_DROP_POINT_LIST: true } });
	await axios.get(url).then(response => {
		dispatch({ type: DRUG_DROP_POINT_LIST, playload: { dataDroppointAll: response.data.data } });
		dispatch({ type: IS_LOADING, status: false, names: { DRUG_DROP_POINT_LIST: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { DRUG_DROP_POINT_LIST: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const drugCreateOrder = (refCode) => async (dispatch) => {

	let url = 'drug/order/create';

	dispatch({ type: IS_LOADING, status: true, names: { DRUG_CREATE_ORDER: true } });
	await axios.post(url, { refCode: refCode }).then(response => {
		let isCreateOrder = true;
		if (_.has(response.data, 'error')) {
			isCreateOrder = false;
		}
		dispatch({ type: DRUG_CREATE_ORDER, playload: { data: response.data.data, isCreateOrder: isCreateOrder } });
		dispatch({ type: IS_LOADING, status: false, names: { DRUG_CREATE_ORDER: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { DRUG_CREATE_ORDER: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const drugCancelOrder = (refCode) => async (dispatch) => {

	let url = 'drug/order/cancel';

	dispatch({ type: IS_LOADING, status: true, names: { DEUG_CANCEL_ORDER: true } });
	await axios.post(url, { refCode: refCode }).then(() => {
		dispatch({ type: IS_LOADING, status: false, names: { DEUG_CANCEL_ORDER: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { DEUG_CANCEL_ORDER: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const drugClear = () => async (dispatch) => {
	dispatch({ type: DRUG_CLEAR });
}