import { INSURANCE_LIST, INSURANCE_CIGNA_CHECK_BENEFIT, INSURANCE_SSO_CHECK_BENEFIT, INSURANCE_MTL_GET_CONSENT, INSURANCE_MTL_CHECK_BENEFIT, INSURANCE_CLEAR_BENEFIT } from './../actions/types';

const initialState = {
	dataAll: [],
	dataBenefit: [],
	dataConsent: {}
};

export default function InsuranceReducer(state = initialState, action) {

	switch (action.type) {
		case INSURANCE_LIST:
		case INSURANCE_CIGNA_CHECK_BENEFIT:
		case INSURANCE_SSO_CHECK_BENEFIT:
		case INSURANCE_MTL_GET_CONSENT:
		case INSURANCE_MTL_CHECK_BENEFIT:
			return { ...state, ...action.playload };
		case INSURANCE_CLEAR_BENEFIT:
			return { ...state, dataBenefit: [], dataConsent: {}, isCignaBenefit: undefined, isSSOBenefit: undefined, isThaireBenefit: undefined, isMTLBenefit: undefined };
		default:
			return { ...state };
	}
};