import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import moment from 'moment-timezone';
import { IS_LOADING, REFERRAL_GET_BY_CODE, REFERRAL_GET_BY_CODE_NAV, REFERRAL_GET_BY_CODE_CHAT, REFERRAL_GET_REVIEW, REFERRAL_GET_APPOINTMENT, REFERRAL_GET_HISTORY, REFERRAL_GET_DRUG_HISTORY, REFERRAL_GET_DOCTOR_BOOKING, REFERRAL_CHECK_BOOKING, REFERRAL_BOOKING, REFERRAL_UPDATE, REFERRAL_CANCEL_BOOKING, REFERRAL_CLEAR, REFERRAL_CHAT_CLEAR, REFERRAL_CHECK_BOOKING_AVAILABLE,REFERRAL_GET_TODAY } from './types'

export const referralGetByCode = (refCode) => async (dispatch) => {

	let url = `referrals?code=${refCode}`;
	let columns = ['refCode', 'patCode', 'payment', 'refPayment', 'information', 'drugAllergyHistory', 'patientAllowToShowHistory', 'attachments', 'doctor', 'type', 'createByApp', 'createdAt', 'doctorPrefix', 'doctorGender', 'statusLogs', 'status', 'couponCode', 'newDocumentPaymentPdfUrl', 'newDocumentDoctorVeifyPdfUrl', 'adviceDoctor', 'pendingInformation', 'medOrder', 'confirmMedOrder', 'acceptMedOrder', 'medPaymentStatus', 'medRef', 'bookingFollowUp', 'patientFirstName', 'patientLastName', 'feedback_rate_start', 'rejectAs', 'cancelReason', 'cancelAccept', 'cancelNeedChangeDoctor', 'medOrderStatus', 'redeemCouponCode', 'updatedAt', 'doctorFirstName', 'doctorLastName','lineUserId','lineOwnerCode','lineSubDomain','patientWaitInRoom','doctorWaitInRoom','userRecivedCall','doctorNotesChiefCompliant','doctorNotesConsultantionSummary','doctorNotesProvisionalDiagnosis','doctorNotesDoctorRecommendations','doctorNotesDrugRecommendations','campaign','campaignDrugStore'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_GET_BY_CODE: true } });
	await axios.get(url).then(response => {
		let data = {};
		if (response.data.data.length > 0) {
			data = response.data.data[0];
		}
		dispatch({ type: REFERRAL_GET_BY_CODE, playload: { data: data } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_BY_CODE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_BY_CODE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralGetByCodeNav = (refCode, type = REFERRAL_GET_BY_CODE_NAV) => async (dispatch) => {

	let url = `referrals?code=${refCode}`;
	let columns = ['refCode', 'patCode', 'doctor', 'doctorPrefix', 'doctorGender', 'information', 'createByApp'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}
	dispatch({ type: REFERRAL_CLEAR });
	await axios.get(url).then(response => {
		let data = {};
		if (response.data.data.length > 0) {
			data = response.data.data[0];
		}
		if (type === REFERRAL_GET_BY_CODE_NAV) {
			dispatch({ type: REFERRAL_GET_BY_CODE_NAV, playload: { dataNav: data, status: 'success' } });
		} else {
			dispatch({ type: REFERRAL_GET_BY_CODE_CHAT, playload: { dataChat: data, status: 'success' } });
		}
	}).catch(error => {
		dispatch({ type: REFERRAL_GET_BY_CODE_CHAT, playload: { status: 'success' } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralGetReview = (docCode, limit = 100) => async (dispatch) => {

	let url = `referrals?doctor=${docCode}&status[0]=complete&isReview=true&limit=${limit}`;
	let columns = ['doctor', 'patientFirstName', 'patientLastName', 'feedback_rate_start', 'feedback_comment', 'createdAt', 'statusLogs', 'patCode'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_GET_REVIEW: true } });
	await axios.get(url).then(response => {
		dispatch({ type: REFERRAL_GET_REVIEW, playload: { dataReviews: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_REVIEW: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_REVIEW: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralGetDoctorBooking = (docCode, createByApp, visitDate, fixColumns = []) => async (dispatch) => {

	let url = `referrals?doctor=${docCode}&status[0]=paid&createByApp=${createByApp}&visitDate=${visitDate}&limit=1000`;
	let columns = _.isArray(fixColumns) && fixColumns.length > 0 ? fixColumns : ['doctor', 'patientFirstName', 'patientLastName', 'feedback_rate_start', 'feedback_comment', 'createdAt', 'statusLogs'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_GET_DOCTOR_BOOKING: true } });
	await axios.get(url).then(response => {
		dispatch({ type: REFERRAL_GET_DOCTOR_BOOKING, playload: { dataDoctorBooking: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_DOCTOR_BOOKING: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_DOCTOR_BOOKING: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralCheckBooking = (patCode, visitDate) => async (dispatch) => {

	let url = `referrals?status[0]=paid&patCode=${patCode}&visitDate=${visitDate}`;
	let columns = ['information'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_CHECK_BOOKING: true } });
	await axios.get(url).then(response => {
		dispatch({ type: REFERRAL_CHECK_BOOKING, playload: { dataCheckBooking: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_CHECK_BOOKING: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_CHECK_BOOKING: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralBooking = (params) => async (dispatch) => {

	let url = `/referrals`;
	params['useInNewDesign'] = true;
	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_BOOKING: true } });
	await axios.post(url, params).then(response => {
		dispatch({ type: REFERRAL_BOOKING, playload: { data: response.data.data } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_BOOKING: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_BOOKING: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralUpdate = (code, params) => async (dispatch) => {

	let url = `/referrals/${code}`;

	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_UPDATE: true } });
	await axios.put(url, params).then(response => {
		let isUpdate = true;
		if (_.has(response.data, 'error')) {
			isUpdate = false;
		}
		dispatch({ type: REFERRAL_UPDATE, playload: { isUpdate: isUpdate } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_UPDATE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_UPDATE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralClear = () => async (dispatch) => {
	dispatch({ type: REFERRAL_CLEAR });
}

export const referralChatClear = () => async (dispatch) => {
	dispatch({ type: REFERRAL_CHAT_CLEAR });
}

export const referralGetHistory = (patCode, createByApp) => async (dispatch) => {
	//need change loading
	let url = `referrals?patCode=${patCode}&limit=100`;
	const companyCode = localStorage.getItem('company-code');
	if(companyCode){
		url += `&refCompanyCode=${companyCode}`;
	}else{
		url += `&createByApp=${createByApp}`;
	}
	let columns = ['refCode', 'patCode', 'information', 'status', 'type', 'createdAt', 'updatedAt', 'doctorPrefix', 'doctorGender', 'statusLogs', 'pendingInformation', 'payment', 'bookingFollowUp', 'medOrder', 'doctorFirstName', 'doctorLastName','lineUserId','lineOwnerCode','lineSubDomain','patientWaitInRoom','doctorWaitInRoom','userRecivedCall','campaign','campaignDrugStore','medPaymentStatus','medicineFollow','trackingChannel','trackingNumber'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}
	dispatch({ type: REFERRAL_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_GET_HISTORY: true } });
	await axios.get(url).then(response => {
		dispatch({ type: REFERRAL_GET_HISTORY, playload: { dataHistories: _.has(response.data, 'data') ? response.data.data : [], status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_HISTORY: false } });
	}).catch(error => {
		dispatch({ type: REFERRAL_GET_HISTORY, playload: { dataHistories: [], status: 'success' }});
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_HISTORY: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralGetDrugHistory = (patCode, createByApp) => async (dispatch) => {

	let url = `referrals?isMed=true&patCode=${patCode}&createByApp=${createByApp}&limit=100`;
	let columns = ['refCode', 'medOrder', 'medOrderStatus', 'medPaymentStatus', 'createdAt', 'updatedAt', 'pharmacyName', 'confirmMedOrder', 'acceptMedOrder', 'deliveryAddress','campaign','campaignDrugStore'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_GET_DRUG_HISTORY: true } });
	await axios.get(url).then(response => {
		dispatch({ type: REFERRAL_GET_DRUG_HISTORY, playload: { dataDrugHistories: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_DRUG_HISTORY: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_DRUG_HISTORY: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralGetDrugByCode = (refCode) => async (dispatch) => {

	let url = `referrals?isMed=true&code=${refCode}`;
	let columns = ['refCode', 'patCode', 'doctor', 'medOrder', 'medOrderStatus', 'medPaymentStatus', 'createdAt', 'pharmacyName', 'confirmMedOrder', 'acceptMedOrder', 'useInsurance', 'newDocumentPaymentMedicinePdfUrl', 'deliveryAddress', 'destinationAddress', 'confirmMedOrderDate', 'shipping', 'discountMedicine', 'couponCode','campaign','campaignDrugStore'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: REFERRAL_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_GET_BY_CODE: true } });
	await axios.get(url).then(response => {
		let data = {};
		if (response.data.data.length > 0) {
			data = response.data.data[0];
		}
		dispatch({ type: REFERRAL_GET_BY_CODE, playload: { data: data, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_BY_CODE: false } });
	}).catch(error => {
		dispatch({ type: REFERRAL_GET_BY_CODE, playload: { status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_BY_CODE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralGetAppointment = (patCode, createByApp) => async (dispatch) => {

	let url = `referrals?patCode=${patCode}&status[0]=paid`;
	const companyCode = localStorage.getItem('company-code');
	if(companyCode){
		url += `&refCompanyCode=${companyCode}`;
	}else{
		url += `&createByApp=${createByApp}`;
	}
	let columns = ['refCode','type', 'subType', 'patCode', 'createByApp', 'information', 'status', 'createdAt', 'doctorPrefix', 'doctorGender', 'pendingInformation','patientWaitInRoom','doctorWaitInRoom','userRecivedCall','campaign','campaignDrugStore'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_GET_HISTORY: true } });
	await axios.get(url).then(response => {
		let dataAppointment = {};
		let currentDate = moment().format('YYYY-MM-DD');
		if (_.has(response.data, 'data')) {
			const dataCurrent = response.data.data.filter((o)=>{
				return o.information.visitDate === currentDate;	
			});
			if (dataCurrent.length >0 ) {
				response.data.data = dataCurrent;
			}
			const data = _.orderBy(response.data.data, ['information.visitDate', 'information.visitTime'], ['asc', 'asc']);
			if (data.length > 0) {
				dataAppointment = data[0];
			}
		}
		dispatch({ type: REFERRAL_GET_APPOINTMENT, playload: { dataAppointment: dataAppointment} });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_HISTORY: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_HISTORY: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralCancelBooking = (code, params) => async (dispatch) => {

	let url = `/referrals/cancel/${code}`;

	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_CANCEL_BOOKING: true } });
	await axios.put(url, params).then(response => {
		dispatch({ type: REFERRAL_CANCEL_BOOKING, playload: { dataCancelBooking: response.data } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_CANCEL_BOOKING: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_CANCEL_BOOKING: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralCancelChangeDoctor = (code, params) => async (dispatch) => {

	let url = `/referrals/cancelNeedChangeDoctor/${code}`;

	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_CANCEL_CHANGE_DOCTOR: true } });
	await axios.put(url, params).then(response => {
		dispatch({ type: REFERRAL_CANCEL_BOOKING, playload: { dataCancelBooking: response.data } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_CANCEL_CHANGE_DOCTOR: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_CANCEL_CHANGE_DOCTOR: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralCheckBookingAvailable = (docCode, patCode, visitData) => async (dispatch) => {

	let url = `/referrals/checkBookingAvailable?docCode=${docCode}&patCode=${patCode}&visitDate=${visitData}`;

	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_CHECK_BOOKING_AVAILABLE: true } });
	await axios.get(url).then(response => {
		dispatch({ type: REFERRAL_CHECK_BOOKING_AVAILABLE, playload: { doctorIdleStatus: _.has(response.data, 'idleStatus') ? response.data.idleStatus : true } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_CHECK_BOOKING_AVAILABLE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_CHECK_BOOKING_AVAILABLE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const referralGetToday = (patCode, createByApp) => async (dispatch) => {
	const date = moment().format('YYYY-MM-DD');
	let url = `referrals?patCode=${patCode}&status[0]=paid&status[1]=complete&createByApp=${createByApp}&sDate=${date}&eDate=${date}`;
	let columns = ['refCode', 'status', 'patCode', 'createByApp', 'information', 'status', 'createdAt', 'doctorPrefix', 'doctorGender', 'pendingInformation','patientWaitInRoom','doctorWaitInRoom','userRecivedCall'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_GET_TODAY: true } });
	await axios.get(url).then(response => {
		let dataToday = {};
		if (_.has(response.data, 'data')) {
			const data = _.orderBy(response.data.data, ['information.visitDate', 'information.visitTime'], ['asc', 'asc']);
			if (data.length > 0) {
				dataToday = data;
			}
		}
		dispatch({ type: REFERRAL_GET_TODAY, playload: { dataToday: dataToday } });
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_TODAY: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_GET_TODAY: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}