import axios from 'axios';
import Bugsnag from '@bugsnag/js'
import _ from 'lodash';
import { IS_LOADING, PATIENT_GET_BY_CODE, PATIENT_SEARCH, PATIENT_CREATE, PATIENT_UPDATE, PATIENT_CLEAR, PATIENT_GET_HEALTH_RECORD, PATIENT_GET_DOCUMENT } from './types';

export const patientGetByCode = (patCode, limit = 99, options = {
  attrType: 'custom',
  columns: ['patCode', 'firstName', 'lastName', 'imageNew', 'gender', 'birthDate', 'mainAccount', 'passportNumber', 'active']
}, nextKey = '', isLoading = true) => async dispatch => {
  let isAddMore = false;
  const newParams = [
		`mainAccount=${patCode}`,
    `limit=${limit}`
  ];
  let url = `patients?${newParams.join('&')}`;

  if (options?.columns?.length > 0) {
    url += `&attrType=custom&${options.columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: PATIENT_CLEAR });
  dispatch({ type: IS_LOADING, status: isLoading, names: { PATIENT_GET_BY_CODE: isLoading } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({
      type: PATIENT_GET_BY_CODE,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_GET_BY_CODE: false } });
  }).catch(err => {
    let error = err;
    dispatch({
      type: PATIENT_GET_BY_CODE,
      playload: {
        data: [],
        status: 'success',
        isAddMore,
        nextKey: ''
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_GET_BY_CODE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const patientSearch = (query) => async (dispatch) => {
	let url = `patients?${query}`.replace('?&', '?');

	let columns = ['patCode', 'firstName', 'lastName', 'imageNew', 'gender', 'birthDate', 'mainAccount'];
	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: PATIENT_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { PATIENT_SEARCH: true } });
	await axios.get(url).then(response => {
		dispatch({ type: PATIENT_SEARCH, playload: { dataSearch: response.data.data, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_SEARCH: false } });
	}).catch(error => {
		dispatch({ type: PATIENT_SEARCH, playload: { dataSearch: [], status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_SEARCH: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const patientCreate = (params) => async (dispatch) => {

	let url = `patients`;

	dispatch({ type: PATIENT_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { PATIENT_CREATE: true } });
	await axios.post(url, params).then(response => {
		let playload = { isDuplicate: false, status: 'success' };
		if (_.has(response.data, 'error')) {
			playload.message = response.data.message
			if (playload.message.indexOf('Duplicate') !== -1) {
				playload.isDuplicate = true;
			}
		} else {
			playload.data = response.data.data;
		}
		dispatch({ type: PATIENT_CREATE, playload: playload });
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_CREATE: false } });
	}).catch(error => {
		dispatch({ type: PATIENT_CREATE, playload: { isDuplicate: false, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_CREATE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const patientUpdate = (patCode, params) => async (dispatch) => {

	let url = `/patients/${patCode}`;

	dispatch({ type: PATIENT_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { PATIENT_UPDATE: true } });
	await axios.put(url, params).then(response => {
		dispatch({ type: PATIENT_UPDATE, playload: { data: response.data, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_UPDATE: false } });
	}).catch(error => {
		dispatch({ type: PATIENT_UPDATE, playload: { data: [], status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_UPDATE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const patientClear = (type = PATIENT_CLEAR) => (dispatch) => {
	dispatch({ type: type });
}

export const patientGetHealthRecord = (patCode,data = [], nextKey = null) => async (dispatch) => {

	let url = `patientHealthRecord?patCode=${patCode}&limit=500`;
	if (!_.isEmpty(nextKey)) {
		url += '&nextKey=' + nextKey;
	}
	let result = [];
	if (_.isArray(data) && data.length > 0) {
		result = data;
	}
	let columns = ['type', 'value', 'createdAt'];
	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { PATIENT_GET_HEALTH_RECORD: true } });
	await axios.get(url).then(response => {
		result = result.concat(response.data.data);
		if (_.has(response.data, 'nextKey') && !_.isEmpty(response.data.nextKey)) {
			dispatch(patientGetHealthRecord(patCode, result, response.data.nextKey));
		} else {
			response.data.data = result;
			dispatch({ type: PATIENT_GET_HEALTH_RECORD, playload: { dataHealthRecords: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { PATIENT_GET_HEALTH_RECORD: false } });
		}
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_GET_HEALTH_RECORD: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	}); 
}

export const patientCreateHealthRecoard = (params) => async (dispatch) => {

	let url = `patientHealthRecord`;

	dispatch({ type: IS_LOADING, status: true, names: { PATIENT_CREATE_HEALTH_RECORD: true } });
	await axios.post(url, params).then(() => {
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_CREATE_HEALTH_RECORD: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_CREATE_HEALTH_RECORD: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const patientGetDocument = (patCode) => async (dispatch) => {

	let url = `patientFiles?patCode=${patCode}&limit=100`;
	let columns = ['id', 'path', 'name', 'type', 'createdAt'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { PATIENT_GET_DOCUMENT: true } });
	await axios.get(url).then(response => {
		dispatch({ type: PATIENT_GET_DOCUMENT, playload: { dataDocumentFiles: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_GET_DOCUMENT: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_GET_DOCUMENT: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const patientCreateDocument = (params) => async (dispatch) => {

	let url = `patientFiles`;

	dispatch({ type: IS_LOADING, status: true, names: { PATIENT_CREATE_DOCUMENT: true } });
	await axios.post(url, params).then(() => {
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_CREATE_DOCUMENT: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_CREATE_DOCUMENT: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const patientDeleteDocument = (id) => async (dispatch) => {

	let url = `patientFiles/${id}`;

	dispatch({ type: IS_LOADING, status: true, names: { PATIENT_DELETE_DOCUMENT: true } });
	await axios.delete(url).then(() => {
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_DELETE_DOCUMENT: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { PATIENT_DELETE_DOCUMENT: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const patientUpdateEkyc = async (patCode, params) => {
	let url = `/patients/${patCode}`;
	return new Promise((reslove) => {
		axios.put(url, params).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const patientUpdateFollowing = async (patCode, params) => {
	let url = `/profile/patient/${patCode}`;
	return new Promise((reslove) => {
		axios.put(url, params).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const getQRcodByCompanyLink = async (code, patCode) => {
  const url = `/accounts/link?companyCode=${code}&userCode=${patCode}`;
  return new Promise(reslove => {
    axios.get(url).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
}