import { lazy } from "react";

const routes = [
	{ path: '/', name: "Home", Element: lazy(() => import("./views/pages/Home")) },
	{ path: '/doctors', name: 'Doctors', Element: lazy(() => import("./views/pages/Doctors")) },
	{ path: '/doctor-profile/:docCode', name: 'DoctorProfile', Element: lazy(() => import("./views/pages/DoctorProfile")) },
	{ path: '/consult/:token', name: 'Consult', Element: lazy(() => import("./views/pages/Consult")) },
	{ path: '/consult/payment/:token', name: 'Payment', Element: lazy(() => import("./views/pages/Payment")) },
	{ path: '/review/:token', name: 'ReviewDoctor ', Element: lazy(() => import("./views/pages/ReviewDoctor")) },
	{ path: '/account', name: 'Account', Element: lazy(() => import("./views/auths/Account")) },
	{ path: '/setting', name: 'Setting ', Element: lazy(() => import("./views/settings/Setting")) },
	{ path: '/assessment', name: 'Assessment ', Element: lazy(() => import("./views/pages/Assessment")) },
	{ path: '/event', name: 'Event ', Element: lazy(() => import("./views/pages/Event")) },
	{ path: '/drug-payment/:token', name: 'DrugPayment ', Element: lazy(() => import("./views/pages/DrugPayment")) },
	{ path: '/drug/payment/:token', name: 'DrugPaymentResult', Element: lazy(() => import("./views/pages/DrugPaymentResult")) },
	{ path: '/bookingtoday', name: 'Bookingtoday ', Element: lazy(() => import("./views/pages/BookingToday")) },
	{ path: '/nursing-home', name: 'NursingHome', Element: lazy(() => import("./views/pages/NursingHome")) },
	{ path: '/nursing-home-service', name: 'NursingHome', Element: lazy(() => import("./views/pages/NursingHome")) },
	{ path: '/nursing-home-price', name: 'NursingHome', Element: lazy(() => import("./views/pages/NursingHome")) },
	{ path: '/nursing-home/:code', name: 'NursingHomeDetail', Element: lazy(() => import("./views/pages/NursingHomeDetail")) },
	{ path: '/booking-nursing-home/:code/:index', name: 'BookingNursingHome', Element: lazy(() => import("./views/pages/BookingNursingHome")) },
	{ path: '/nursing/payment/:token', name: 'NursingPaymentResult ', Element: lazy(() => import("./views/pages/NursingPaymentResult")) },
	{ path: '/nursing/cancel-booking/:code', name: 'NursingCancelBooking', Element: lazy(() => import("./views/pages/NursingCancelBooking")) },
];

export default routes; 