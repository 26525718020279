import React from 'react';
import { createRoot } from 'react-dom/client';
import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk'
import { Amplify } from 'aws-amplify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import reducer from './reducers';
import { GOOGLE_CLIENT_ID } from './configs';
import AWSConfig from './configs/aws';
import './helpers/axios';
import './i18n';

require('moment/locale/th');

Amplify.configure(AWSConfig.config);
Bugsnag.start({
	apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
	plugins: [new BugsnagPluginReact()],
	releaseStage: process.env.REACT_APP_ENV,
	enabledReleaseStages: ['production', 'staging', 'development']
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
const store = createStore(reducer, {}, applyMiddleware(reduxThunk));

createRoot(document.getElementById('root')).render(
	<ErrorBoundary>
		<Provider store={store}>
			<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
				<App />
			</GoogleOAuthProvider>
		</Provider>
	</ErrorBoundary>,
);

reportWebVitals();