
import axios from 'axios';
import Bugsnag from '@bugsnag/js'
import _ from 'lodash';
import { IS_LOADING, FEEDBACK_SEND } from './types';

export const feedbackSend = (params) => async (dispatch) => {

	let url = '/feedbacks';

	dispatch({ type: IS_LOADING, status: true, names: { FEEDBACK_SEND: true } });
	await axios.post(url, params).then(response => {
		let message = response.data.message;
		if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
			message = response.data.errors[0].msg;
		}
		dispatch({ type: FEEDBACK_SEND, playload: { data: _.has(response.data, 'data') ? response.data.data : {}, message: message } });
		dispatch({ type: IS_LOADING, status: false, names: { FEEDBACK_SEND: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { FEEDBACK_SEND: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}