import { NOTIFICATION_LIST,NOTIFICATION_SEND_LINE_MESSAGE } from './../actions/types';

const initialState = {
	data: [],
	line: {}
};

export default function NotificationReducer(state = initialState, action) {

	switch (action.type) {
		case NOTIFICATION_LIST:
			return { ...state, ...action.playload };
		case NOTIFICATION_SEND_LINE_MESSAGE:
			return { ...state, ...action.playload };
		default:
			return { ...state };
	}
};