import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const Loading = (props) => {

	const { t } = useTranslation();

	return (
		<section className="loading">
			<div className="loading-block">
				<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
				{(_.has(props, 'message') && !_.isUndefined(props.message)) ? (
					<div className="text-capitalize">{t(props.message)}</div>
				) : (
					<div className="text-capitalize">{t('Loading')}</div>
				)}
			</div>
		</section>
	);
}
export default Loading;