import { HOSPITAL_LIST } from './../actions/types';

const initialState = {
	dataAll: []
};

export default function HospitalReducer(state = initialState, action) {

	switch (action.type) {
		case HOSPITAL_LIST:
			return { ...state, ...action.playload };
		default:
			return { ...state };
	}
};