import { MASTER_DOCTOR_PREFIX, MASTER_GROUP_SPECIALTIES, MASTER_TAGS_AND_EVENTS, MASTER_APPS, MASTER_MANUALS, MASTER_FAQS, MASTER_SYMPTOM, MASTER_ORGAN, MASTER_LANG, MASTER_DEACTIVATION_REASON, MASTER_CANCEL_BOOKING_REASON, MASTER_LINE_OWNER_CODE, MASTER_NURSING_HOME_PRICE_RANGE, MASTER_NURSING_HOME_ROOM_STYLE, MASTER_NURSING_HOME_ILLNESS, MASTER_CAMPAIGNS } from './../actions/types';

const initialState = {
	doctorPrefixs: [],
	groupSpecialties: [],
	tagsAndEvents: [],
	apps: [],
	manuals: [],
	faqs: [],
	symptoms: [],
	organs: [],
	langs: [],
	deactivateReasons: [],
	cancelBookingReasons: [],
	lineOwnerCode: [],
	priceRanges: [],
	roomStyles: [],
	illnesses: [],
	campaigns: []
};

export default function MasterReducer(state = initialState, action) {

	switch (action.type) {
		case MASTER_DOCTOR_PREFIX:
		case MASTER_GROUP_SPECIALTIES:
		case MASTER_TAGS_AND_EVENTS:
		case MASTER_APPS:
		case MASTER_MANUALS:
		case MASTER_FAQS:
		case MASTER_SYMPTOM:
		case MASTER_ORGAN:
		case MASTER_LANG:
		case MASTER_DEACTIVATION_REASON:
		case MASTER_LINE_OWNER_CODE:
		case MASTER_CANCEL_BOOKING_REASON:
		case MASTER_NURSING_HOME_PRICE_RANGE:
		case MASTER_NURSING_HOME_ROOM_STYLE:
		case MASTER_NURSING_HOME_ILLNESS:
		case MASTER_CAMPAIGNS:
			return { ...state, ...action.playload };
		default:
			return { ...state };
	}
};