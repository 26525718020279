import { PACKAGE_LIST } from './../actions/types';

const initialState = {
	data: []
};

export default function PackageReducer(state = initialState, action) {

	switch (action.type) {
		case PACKAGE_LIST:
			return { ...state, ...action.playload };
		default:
			return { ...state };
	}
};