import { AUTH_SIGN_IN, AUTH_SIGN_OUT, AUTH_REGISTER, AUTH_CHANGE_PASSWORD, AUTH_FORGOT_PASSWORD, AUTH_FORGOT_PASSWORD_VERIFY_OTP, AUTH_FORGOT_PASSWORD_CHANGE, AUTH_CLEAR, AUTH_ADD_CONTACT, AUTH_ADD_CONTACT_VERIFY, AUTH_CLEAR_CONTACT_VERIFY, AUTH_ADD_CONTACT_UPDATE, AUTH_CHANGE_CONTACT_DEFAULT, AUTH_CLOST_ACCOUNT,AUTH_GET_FAMILY } from './../actions/types';

const initialState = {
	loggedIn: false,
	accessToken: null,
	profile: {},
	family: [],
	doctorHistories: []
}
export default function AuthReducer(state = initialState, action) {
	switch (action.type) {
		case AUTH_REGISTER:
			return { ...state, ...action.playload };
		case AUTH_SIGN_IN:
			return { ...state, ...action.playload };
		case AUTH_CHANGE_PASSWORD:
			return { ...state, ...action.playload };
		case AUTH_FORGOT_PASSWORD:
			return { ...state, ...action.playload };
		case AUTH_FORGOT_PASSWORD_VERIFY_OTP:
			return { ...state, ...action.playload };
		case AUTH_FORGOT_PASSWORD_CHANGE:
			return { ...state, ...action.playload };
		case AUTH_ADD_CONTACT:
			return { ...state, ...action.playload };
		case AUTH_ADD_CONTACT_VERIFY:
			return { ...state, ...action.playload };
		case AUTH_ADD_CONTACT_UPDATE:
			return { ...state, ...action.playload };
		case AUTH_CHANGE_CONTACT_DEFAULT:
			return { ...state, ...action.playload };
		case AUTH_CLOST_ACCOUNT:
			return { ...state, ...action.playload };
		case AUTH_SIGN_OUT:
			return { state };
		case AUTH_GET_FAMILY:
			return { ...state, ...action.playload };
		case AUTH_CLEAR:
			return {
				...state,
				message: undefined,
				isAddContact: undefined,
				verify: undefined,
				isChangePasswordSuccess: undefined,
				isCloseAccount: undefined,
				status: undefined
			};
		case AUTH_CLEAR_CONTACT_VERIFY:
			return { ...state, message: undefined, isAddContactVerify: undefined, isAddContactUpdate: undefined, isChangeContactDefault: undefined };
		default:
			return { ...state };
	}
};