import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, NURSING_HOME_LIST, NURSING_HOME_LIST_RECOMMEND, NURSING_HOME_LIST_HOT_DEAL, NURSING_HOME_GET_BY_CODE, NURSING_HOME_BOOKING, NURSING_HOME_GET_HISTORY, NURSING_HOME_BOOKING_GET_BY_CODE, NURSING_HOME_BOOKING_UPDATE, NURSING_HOME_BOOKING_CANCEL, NURSING_HOME_BOOKING_CLEAR, NURSING_HOME_VISIT, NURSING_HOME_BOOKING_LIST } from './types'

export const nursingHomeList = (query = null, nextKey = null) => async (dispatch) => {

	let url = `nursingHomeCenter?status=active&limit=100`;
	if (!_.isEmpty(query)) {
		url += `&${query}`;
	}
	if (!_.isEmpty(nextKey)) {
		url += '&nextKey=' + nextKey;
	}

	dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_LIST: true } });
	await axios.get(url.replace('?&', '?')).then(response => {
		dispatch({ type: NURSING_HOME_LIST, playload: { dataAll: response.data.data || [], nextKey: response.data.nextKey } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_LIST: false } });
	}).catch(error => {
		dispatch({ type: NURSING_HOME_LIST, playload: { dataAll: [] } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_LIST: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const nursingHomeListRecommend = () => async (dispatch) => {

	let url = `nursingHomeCenter?status=active&isRecommend=true&limit=100`;

	dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_LIST_RECOMMEND: true } });
	await axios.get(url.replace('?&', '?')).then(response => {
		dispatch({ type: NURSING_HOME_LIST_RECOMMEND, playload: { dataRecommends: response.data.data || [] } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_LIST_RECOMMEND: false } });
	}).catch(error => {
		dispatch({ type: NURSING_HOME_LIST_RECOMMEND, playload: { dataRecommends: [] } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_LIST_RECOMMEND: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const nursingHomeListHotDeal = () => async (dispatch) => {

	let url = `nursingHomeCenter?status=active&isHotDeal=true&limit=100`;

	dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_LIST_HOT_DEAL: true } });
	await axios.get(url.replace('?&', '?')).then(response => {
		dispatch({ type: NURSING_HOME_LIST_HOT_DEAL, playload: { dataHotDeals: response.data.data || [] } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_LIST_HOT_DEAL: false } });
	}).catch(error => {
		dispatch({ type: NURSING_HOME_LIST_HOT_DEAL, playload: { dataHotDeals: [] } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_LIST_HOT_DEAL: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const nursingHomeGetByCode = (code) => async (dispatch) => {

	let url = `nursingHomeCenter?code=${code}`;

	dispatch({ type: NURSING_HOME_BOOKING_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_GET_BY_CODE: true } });
	await axios.get(url).then(response => {
		let data = {};
		if (response.data.data.length > 0) {
			data = response.data.data[0];
		}
		dispatch({ type: NURSING_HOME_GET_BY_CODE, playload: { data: data, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_GET_BY_CODE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_GET_BY_CODE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const nursingHomeBooking = (params) => async (dispatch) => {

	let url = `nursingHomeBooking`;

	dispatch({ type: NURSING_HOME_BOOKING_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_BOOKING: true } });
	await axios.post(url, params).then(response => {
		dispatch({ type: NURSING_HOME_BOOKING, playload: { dataBooking: response.data.data, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING: false } });
	}).catch(error => {
		dispatch({ type: NURSING_HOME_BOOKING, playload: { dataBooking: {}, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const nursingHomeBookingUpdate = (code, params) => async (dispatch) => {

	const url = `nursingHomeBooking/${code}`;

	dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_BOOKING_UPDATE: true } });
	await axios.put(url, params).then(response => {
		let isUpdate = true;
		if (_.has(response.data, 'error'))
			isUpdate = false;

		dispatch({ type: NURSING_HOME_BOOKING_UPDATE, playload: { isUpdate: isUpdate } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_UPDATE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_UPDATE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const nursingHomeBookingCancel = (code, params) => async (dispatch) => {

	const url = `nursingHomeBooking/cancel/${code}`;

	dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_BOOKING_CANCEL: true } });
	await axios.put(url, params).then(response => {
		let isCancel = true;
		if (_.has(response.data, 'error'))
			isCancel = false;

		dispatch({ type: NURSING_HOME_BOOKING_CANCEL, playload: { isCancel: isCancel } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_CANCEL: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_CANCEL: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const nursingHomeGetHistory = (patCode) => async (dispatch) => {

	let url = `nursingHomeBooking?patCode=${patCode}&limit=100`;

	dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_GET_HISTORY: true } });
	await axios.get(url).then(response => {
		dispatch({ type: NURSING_HOME_GET_HISTORY, playload: { dataHistories: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_GET_HISTORY: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_GET_HISTORY: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const nursingHomeBookingGetByCode = (nhbCode) => async (dispatch) => {

	let url = `nursingHomeBooking?code=${nhbCode}`;

	dispatch({ type: NURSING_HOME_BOOKING_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_BOOKING_GET_BY_CODE: true } });
	await axios.get(url).then(response => {
		let data = {};
		let status = 'error';
		if (response?.data?.data?.length > 0) {
			data = response.data.data[0];
			status = 'success';
		}
		dispatch({ type: NURSING_HOME_BOOKING_GET_BY_CODE, playload: { dataBooking: data, status } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_GET_BY_CODE: false } });
	}).catch(error => {
		dispatch({ type: NURSING_HOME_BOOKING_GET_BY_CODE, playload: { dataBooking: {}, status: 'success' }});
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_GET_BY_CODE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const nursingHomeBookingList = (nhcCode, params = [], limit = 15, nextKey = '') => async dispatch => {
  let isAddMore = false;
  let url = `nursingHomeBooking?nhcCode=${nhcCode}&limit=${limit}`;
	const newParams = params;
  if (nextKey !== '') {
    isAddMore = true;
    newParams.push(`nextKey=${nextKey}`);
  }
  if (newParams?.length > 0) {
    url += `&${newParams.join('&')}`;
  }

  dispatch({ type: NURSING_HOME_BOOKING_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_BOOKING_LIST: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({
      type: NURSING_HOME_BOOKING_LIST,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_LIST: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: NURSING_HOME_BOOKING_LIST, playload: { data: [], status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_LIST: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const nursingHomeBookingClear = () => async (dispatch) => {
	dispatch({ type: NURSING_HOME_BOOKING_CLEAR });
}

export const nursingHomeVisit = (params) => async (dispatch) => {

	let url = `nursingHomeVisit`;

	dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_VISIT: true } });
	await axios.post(url, params).then(response => {
		dispatch({ type: NURSING_HOME_VISIT, playload: { dataVisit: response.data.data } });
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_VISIT: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_VISIT: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const visitPageCenter = async (code, params) => {
	let url = `/nursingHomeCenter/visitPage/${code}`;
	return new Promise((reslove) => {
		axios.put(url, params).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const visitRoomCenter = async (code, room, params) => {
	let url = `/nursingHomeCenter/visitRoom/${code}/${room}`;
	return new Promise((reslove) => {
		axios.put(url, params).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}