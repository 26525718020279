import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, MASTER_DOCTOR_PREFIX, MASTER_GROUP_SPECIALTIES, MASTER_TAGS_AND_EVENTS, MASTER_APPS, MASTER_MANUALS, MASTER_FAQS, MASTER_SYMPTOM, MASTER_ORGAN, MASTER_LANG, MASTER_DEACTIVATION_REASON, MASTER_CANCEL_BOOKING_REASON, MASTER_LINE_OWNER_CODE, MASTER_NURSING_HOME_PRICE_RANGE, MASTER_NURSING_HOME_ROOM_STYLE, MASTER_NURSING_HOME_ILLNESS, MASTER_CAMPAIGNS } from './types'

export const masterDoctorPrefix = () => async (dispatch) => {

	let url = 'master/doctorPrefix';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_DOCTOR_PREFIX: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_DOCTOR_PREFIX, playload: { doctorPrefixs: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_DOCTOR_PREFIX: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_DOCTOR_PREFIX: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterGroupSpecialties = () => async (dispatch) => {

	let url = 'master/groupSpecialtys';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_GROUP_SPECIALTIES: true } });
	await axios.get(url).then(response => {
		dispatch({
			type: MASTER_GROUP_SPECIALTIES, playload: {
				groupSpecialties: _.has(response.data, 'data') ? response.data.data.filter((o) => {
					return _.has(o, 'code') && _.has(o, 'useInApps');
				}) : []
			}
		});
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_GROUP_SPECIALTIES: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_GROUP_SPECIALTIES: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterTagsAndEvents = () => async (dispatch) => {

	let url = 'master/tagsAndEvents?onPeriod=1';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_TAGS_AND_EVENTS: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_TAGS_AND_EVENTS, playload: { tagsAndEvents: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_TAGS_AND_EVENTS: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_TAGS_AND_EVENTS: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterApps = () => async (dispatch) => {

	let url = 'master/apps';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_APPS: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_APPS, playload: { apps: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_APPS: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_APPS: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterManuals = () => async (dispatch) => {

	let url = 'master/manuals';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_MANUALS: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_MANUALS, playload: { manuals: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_MANUALS: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_MANUALS: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterFAQs = () => async (dispatch) => {

	let url = 'master/faqs';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_FAQS: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_FAQS, playload: { faqs: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_FAQS: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_FAQS: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterSymptom = () => async (dispatch) => {

	let url = 'master/symptom';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_SYMPTOM: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_SYMPTOM, playload: { symptoms: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_SYMPTOM: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_SYMPTOM: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterOrgan = () => async (dispatch) => {

	let url = 'master/organ';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_ORGAN: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_ORGAN, playload: { organs: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_ORGAN: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_ORGAN: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterLang = () => async (dispatch) => {

	let url = 'master/lang';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_LANG: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_LANG, playload: { langs: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_LANG: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_LANG: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterDeactivationReason = () => async (dispatch) => {

	let url = 'master/deactivationReason';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_DEACTIVATION_REASON: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_DEACTIVATION_REASON, playload: { deactivateReasons: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_DEACTIVATION_REASON: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_DEACTIVATION_REASON: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterCancelBookingReason = () => async (dispatch) => {

	let url = 'master/cancelReason';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_CANCEL_BOOKING_REASON: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_CANCEL_BOOKING_REASON, playload: { cancelBookingReasons: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_CANCEL_BOOKING_REASON: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_CANCEL_BOOKING_REASON: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterLineOwnerCode = () => async (dispatch) => {

	let url = 'master/lineOwnerCode';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_LINE_OWNER_CODE: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_LINE_OWNER_CODE, playload: { lineOwnerCode: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_LINE_OWNER_CODE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_LINE_OWNER_CODE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterNursingHomePriceRange = () => async (dispatch) => {

	let url = 'master/nursingHomePriceRange';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_NURSING_HOME_PRICE_RANGE: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_NURSING_HOME_PRICE_RANGE, playload: { priceRanges: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_NURSING_HOME_PRICE_RANGE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_NURSING_HOME_PRICE_RANGE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterNursingHomeRoomStyle = () => async (dispatch) => {

	let url = 'master/nursingHomeRoomLayout';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_NURSING_HOME_ROOM_STYLE: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_NURSING_HOME_ROOM_STYLE, playload: { roomStyles: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_NURSING_HOME_ROOM_STYLE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_NURSING_HOME_ROOM_STYLE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterNursingHomeIllness = () => async (dispatch) => {

	let url = 'master/nursingHomeIllness';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_NURSING_HOME_ILLNESS: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_NURSING_HOME_ILLNESS, playload: { illnesses: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_NURSING_HOME_ILLNESS: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_NURSING_HOME_ILLNESS: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const masterCampaigns = () => async (dispatch) => {

	let url = 'master/campaigns';

	dispatch({ type: IS_LOADING, status: true, names: { MASTER_CAMPAIGNS: true } });
	await axios.get(url).then(response => {
		dispatch({ type: MASTER_CAMPAIGNS, playload: { campaigns: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_CAMPAIGNS: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_CAMPAIGNS: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}