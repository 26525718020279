import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, COUPON_CHECK, COUPON_USE, COUPON_GET_BY_CODE, COUPON_SEARCH_BY_CODE } from './types'

export const couponCheck = (code, patCode, appName, isDrug) => async (dispatch) => {

	let url = `coupons/checkcoupon/${code.toLocaleLowerCase()}`;
	let result = { code: code, msg: 'not_found_coupon' };

	dispatch({ type: IS_LOADING, status: true, names: { COUPON_CHECK: true } });
	await axios.put(url, { appName: appName, checkBy: patCode, isDrug }).then(response => {
		const data = response.data;
		if (data.success) {
			result = {
				code: code,
				couponType: data?.data?.couponType || '',
				type: data.data.discountType,
				amount: data.data.doctorCost,
				medicineType: data.data.medicineDiscountType,
				medicineAmount: data.data.medicineCost,
				useInsteadOfMoney: data.data.useInsteadOfMoney,
				isDemo: data.data.isDemo ? true : false,
				volunteerOnly: data.data.volunteerOnly,
				freeDelivery: data.data.freeDelivery,
				nursingType: data.data.nursingDiscountType,
				nursingAmount: data.data.nursingDiscountAmount
			}
			if (data.data.discountShareAllProcess) {
				result.type = data.data.discountShareProcessType;
				result.amount = data.data.discountShareProcessDoctor ? data.data.discountShareProcessAmounts : result.amount;
				result.share = {
					medicine: data.data.discountShareProcessMedicine,
					delivery: data.data.discountShareProcessDelivery
				}
			}
		} else {
			result = { code: code, msg: data.message }
		}
		dispatch({ type: COUPON_CHECK, playload: { data: result } });
		dispatch({ type: IS_LOADING, status: false, names: { COUPON_CHECK: false } });
	}).catch(error => {
		result = { code: code, msg: error?.response?.data?.message }
		dispatch({ type: COUPON_CHECK, playload: { data: result } });
		dispatch({ type: IS_LOADING, status: false, names: { COUPON_CHECK: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const couponUse = (code, appName, patCode, docCode, type = 'commercial', isDrug = undefined) => async (dispatch) => {

	let url = `coupons/use/${code.toLocaleLowerCase()}`;

	dispatch({ type: IS_LOADING, status: true, names: { COUPON_USE: true } });
	await axios.put(url, { appName: appName, usedBy: patCode, docCode: docCode, type: type, isDrug }).then(response => {
		const data = response.data;
		dispatch({
			type: COUPON_USE, playload: {
				useCoupon: {
					code: data.data.code,
					isUsed: true,
					msg: 'used_coupon_success'
				}
			}
		});
		dispatch({ type: IS_LOADING, status: false, names: { COUPON_USE: false } });
	}).catch(error => {
		dispatch({ type: COUPON_USE, playload: { useCoupon: { code: code, isUsed: false, msg: error?.response?.data?.message || 'Internal Server Error' } } });
		dispatch({ type: IS_LOADING, status: false, names: { COUPON_USE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const couponGetByCode = (arrCode) => async (dispatch) => {

	if (!_.isEmpty(arrCode)) {
		let url = '/coupons';
		arrCode.forEach((coupon, index) => {
			url += `${index === 0 ? '?' : '&'}codes[${index}]=${coupon.toLocaleLowerCase()}`;
		})

		dispatch({ type: IS_LOADING, status: true, names: { COUPON_GET_BY_CODE: true } });
		await axios.get(url).then(response => {
			dispatch({ type: COUPON_GET_BY_CODE, playload: { myCoupons: response.data.data } });
			dispatch({ type: IS_LOADING, status: false, names: { COUPON_GET_BY_CODE: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { COUPON_GET_BY_CODE: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	} else {
		dispatch({ type: COUPON_GET_BY_CODE, playload: { myCoupons: [] } });
		dispatch({ type: IS_LOADING, status: false, names: { COUPON_GET_BY_CODE: false } });
	}
}

export const couponSearchByCode = (code) => async (dispatch) => {

	let url = `/coupons?code=${code}`;

	dispatch({ type: IS_LOADING, status: true, names: { COUPON_SEARCH_BY_CODE: true } });
	await axios.get(url).then(response => {
		let data = {};
		if (response.data.data.length > 0) {
			data = response.data.data[0];
		}
		dispatch({ type: COUPON_SEARCH_BY_CODE, playload: { searchCoupon: data } });
		dispatch({ type: IS_LOADING, status: false, names: { COUPON_SEARCH_BY_CODE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { COUPON_SEARCH_BY_CODE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}