const WhiteLabelConfig = {
	mapping: [
		/* dev */
		{ companyCode: 'CN1688962901661', appCode: 'co.doctoratoz.puhealth', domainName: 'localhost', companyName: "กรมอนามัย" },
		/* dev */
		{ companyCode: 'CN1598928649546', appCode: 'co.doctoratoz.mmt', domainName: 'devmmtm2.agyhero.com', companyName: "มิตรไมตรีคลินิก (mmt)" },
		{ companyCode: 'CN1688117055706', appCode: 'co.doctoratoz.puhealth', domainName: 'puhealth.agyhero.com', companyName: "กรมอนามัย" },
		{ companyCode: 'CN1691739003644', appCode: 'co.doctoratoz.bkkgi', domainName: 'bkkgi.agyhero.com', companyName: "BkkGi" },
		{ companyCode: 'CN1693232674837', appCode: 'co.doctoratoz.telepharmacy', domainName: 'telepharmacy.agyhero.com', companyName: "Telepharmacy" },
		{ companyCode: 'CN1643791013947', appCode: 'co.doctoratoz.demo', domainName: 'demo.agyhero.com', companyName: "Demo" }
	]
};
export default WhiteLabelConfig;