import { EMAIL_SEND_OTP, EMAIL_VERIFY_OTP } from '../actions/types';

const initialState = {
	ref: null
};

export default function EmailReducer(state = initialState, action) {

	switch (action.type) {
		case EMAIL_SEND_OTP:
		case EMAIL_VERIFY_OTP:
			return { ...state, ...action.playload };
		default:
			return { ...state };
	}
}