import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, DOCTOR_LIST, DOCTOR_LIST_RECOMMEND, DOCTOR_LIST_FAVORITE, DOCTOR_GET_BY_CODE, DOCTOR_CHECK_ONLINE, DOCTOR_CLEAR } from './types'

export const doctorList = (app, query = null, limit = 1000) => async (dispatch) => {
    
	limit = limit === null ? 1000 : limit;
	let url = `doctors/lists?limit=${limit}&onInNewDesign=1`; 
	const companyCode = localStorage.getItem('company-code');
	if(companyCode){
		url += `&company=${companyCode}`;
	}else{
		url += `&app=${app}`;
	}
	if (!_.isEmpty(query)) {
		url += `&${query}`;
	}

	let columns = ['docCode', 'isOnline', 'image', 'imageNew', 'gender', 'prefix', 'firstName', 'lastName', 'groupSpecialtys', 'reviewRating', 'telemedSchedules', 'doctorPriceByApps', 'doctorPrice', 'timeSlotRange', 'languageSpoken', 'status', 'shareDoctorWithDaz', 'commercial', 'acceptInsure', 'dataLang','active','telemedBooking','telemedOnline','campaigns'];
	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: DOCTOR_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { DOCTOR_LIST: true } });
	await axios.get(url.replace('&&', '&')).then(response => {
		dispatch({ type: DOCTOR_LIST, playload: { dataAll: response.data.data, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_LIST: false } });
	}).catch(error => {
		dispatch({ type: DOCTOR_LIST, playload: { dataAll: [], status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_LIST: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const doctorListRecommend = (patCode = 'empty', limit = 3) => async (dispatch) => {

	let url = `doctors/suggestion/${patCode}?limit=${limit}&onInNewDesign=1`;
	const companyCode = localStorage.getItem('company-code');
	if(companyCode){
		url += `&company=${companyCode}`;
	}
	let columns = ['docCode', 'isOnline', 'image', 'imageNew', 'gender', 'prefix', 'firstName', 'lastName', 'groupSpecialtys', 'reviewRating', 'telemedSchedules', 'doctorPriceByApps', 'doctorPrice', 'timeSlotRange', 'languageSpoken', 'status', 'acceptInsure', 'dataLang','active','telemedBooking','telemedOnline','campaigns'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: DOCTOR_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { DOCTOR_LIST_RECOMMEND: true } });
	await axios.get(url).then(response => {
		dispatch({ type: DOCTOR_LIST_RECOMMEND, playload: { dataRecommends: response.data.data, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_LIST_RECOMMEND: false } });
	}).catch(error => {
		dispatch({ type: DOCTOR_LIST_RECOMMEND, playload: { dataRecommends: [], status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_LIST_RECOMMEND: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const doctorListFavorite = (patCode) => async (dispatch) => {

	let url = `doctors/favorite/${patCode}?limit=100&onInNewDesign=1`;
	const companyCode = localStorage.getItem('company-code');
	if(companyCode){
		url += `&company=${companyCode}`;
	}
	let columns = ['docCode', 'isOnline', 'image', 'imageNew', 'gender', 'prefix', 'firstName', 'lastName', 'groupSpecialtys', 'reviewRating', 'telemedSchedules', 'doctorPriceByApps', 'doctorPrice', 'timeSlotRange', 'languageSpoken', 'status', 'acceptInsure', 'dataLang','active','telemedBooking','telemedOnline','campaigns'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { DOCTOR_LIST_FAVORITE: true } });
	await axios.get(url).then(response => {
		dispatch({ type: DOCTOR_LIST_FAVORITE, playload: { dataFavorites: response.data.data } });
		dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_LIST_FAVORITE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_LIST_FAVORITE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const doctorGetByCode = (docCode, mapInsure = false, fixColumns = []) => async (dispatch) => {

	let url = `doctors?code=${docCode}${mapInsure ? '&isMapInsure=1' : ''}`;
	let columns = _.isArray(fixColumns) && fixColumns.length > 0 ? fixColumns : ['docCode', 'isOnline', 'image', 'imageNew', 'gender', 'prefix', 'firstName', 'lastName', 'groupSpecialtys', 'reviewRating', 'telemedSchedules', 'doctorPriceByApps', 'doctorPrice', 'timeSlotRange', 'languageSpoken', 'educations', 'dataLang', 'hospitals', 'status', 'commercial', 'symptom', 'attachments', 'acceptInsure', 'acceptInsureLits','active','telemedBooking','telemedOnline','campaigns'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { DOCTOR_GET_BY_CODE: true } });
	await axios.get(url).then(response => {
		let data = {};
		if (response.data.data.length > 0) {
			data = response.data.data[0];
		}
		dispatch({ type: DOCTOR_GET_BY_CODE, playload: { data: data } });
		dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_GET_BY_CODE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_GET_BY_CODE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const doctorCheckOnline = (docCode) => async (dispatch) => {

	let url = `doctors?code=${docCode}`;
	let columns = ['docCode', 'isOnline', 'status','active','telemedBooking','telemedOnline'];

	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { DOCTOR_CHECK_ONLINE: true } });
	await axios.get(url).then(response => {
		if (response.data.data.length > 0) {
			const data = response.data.data[0];
			dispatch({ type: DOCTOR_CHECK_ONLINE, isOnline: data.isOnline, status: data.status });
		} else {
			dispatch({ type: DOCTOR_CHECK_ONLINE, isOnline: false });
		}
		dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_CHECK_ONLINE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_CHECK_ONLINE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const doctorClear = () => async (dispatch) => {
	dispatch({ type: DOCTOR_CLEAR });
}