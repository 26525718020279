import { USER_CHECK_PROFILE, USER_CLEAR } from './../actions/types';

const initialState = {

};

export default function UserReducer(state = initialState, action) {

	switch (action.type) {
		case USER_CHECK_PROFILE:
			return { ...state, ...action.playload };
		case USER_CLEAR:
			return { ...state, haveProfile: undefined };
		default:
			return { ...state };
	}
};