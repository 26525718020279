import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import moment from 'moment-timezone';
import _ from "lodash";
import { firebase, database } from '../helpers/firebase';

export const alert = (data, callback = () => { }) => {
	const Container = (props) => <>{props.children}</>;

	const link = !_.isUndefined(data.link) ? data.link : '#';
	const type = !_.isUndefined(data.type) ? data.type : 'primary-900';

	if (data.fill) {
		let html = <Container>
			<Row>
				<Col>
					{!_.isUndefined(data.title) && (<div className={`alert-title`}>{data.title}</div>)}
					{!_.isUndefined(data.message) && (<div className="alert-message">{data.message}</div>)}
				</Col>
			</Row>
		</Container>

		if (type === 'warning') {
			toast.warn(html, { theme: 'colored', closeButton: false, icon: <Icon icon='ep:warning' width="39px" /> });
		} else if (type === 'error') {
			toast.error(html, { theme: 'colored', closeButton: false, icon: <Icon icon='ion:close-circle' width="39px" /> });
		} else {
			toast.success(html, { theme: 'colored', closeButton: false, icon: <Icon icon='ion:checkmark-circle' width="39px" /> });
		}
	} else {
		let html = <Container>
			<Row className={`${!_.isUndefined(data.datetime) ? 'alert-block' : ''}`} tag={link !== '#' ? 'a' : 'div'} href={link} target={!_.isUndefined(data.target) ? data.target : '_self'} onClick={callback.bind(this)}>
				{!_.isUndefined(data.icon) && (
					<Col xs="auto">
						<div className={`alert-icon text-${type}`}>
							<Icon icon={data.icon} width="28px" />
						</div>
					</Col>
				)}
				<Col className={`align-self-center ${!_.isUndefined(data.icon) ? 'ps-0' : ''}`}>
					{!_.isUndefined(data.title) && (<div className={`alert-title text-${type}`}>{data.title}</div>)}
					{!_.isUndefined(data.message) && (<div className="alert-message">{data.message}</div>)}
				</Col>
			</Row>
			{!_.isUndefined(data.datetime) && (
				<span className='alert-time'>{moment(data.datetime).fromNow()}</span>
			)}
		</Container>
		toast(html);
	}
}

export const notification = (status, activity, patCode, docCode, message) => {
	firebase.set(firebase.push(firebase.ref(database, 'notifications')), {
		activity: activity,
		form: {
			code: patCode,
			role: 'patient'
		},
		to_code: docCode,
		to_role: 'doctor',
		message: message,
		readBy: ['NO-READ'],
		alertBy: ['NO-ALERT'],
		roleAction: {
			doctor: true
		},
		status: status,
		createAt: moment().format('x'),
		updateAt: moment().format('x')
	});
}