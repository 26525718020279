import { WHITELABEL_SETTING,WHITELABEL_SETTING_THEME,WHITELABEL_SETTING_OPTION } from './../actions/types';

const initialState = {
	data: {},
	theme: {},
	hospital: '',
	payment: {}
};

export default function WhitelabelReducer(state = initialState, action) {

	switch (action.type) {
		case WHITELABEL_SETTING:
			return { ...state, data: action.playload.data };
		case WHITELABEL_SETTING_THEME:
			return { ...state, theme: action.playload.data };
		case WHITELABEL_SETTING_OPTION:
			return { ...state, option: action.playload.data.hospital, payment: action.playload.data.payment };
		default:
			return { ...state };
	}
};