import { SHORT_LINK } from './../actions/types';

const initialState = {
	name: '',
	data: {}
};

export default function ShortlinkReducer(state = initialState, action) {

	switch (action.type) {
		case SHORT_LINK:
			return { ...state, name: action.name, data: action.data };
		default:
			return { ...state };
	}
};