import { NURSING_HOME_LIST, NURSING_HOME_LIST_RECOMMEND, NURSING_HOME_LIST_HOT_DEAL, NURSING_HOME_GET_BY_CODE, NURSING_HOME_BOOKING, NURSING_HOME_GET_HISTORY, NURSING_HOME_BOOKING_GET_BY_CODE, NURSING_HOME_BOOKING_UPDATE, NURSING_HOME_BOOKING_CANCEL, NURSING_HOME_BOOKING_CLEAR, NURSING_HOME_BOOKING_LIST, NURSING_HOME_VISIT } from './../actions/types';

const initialState = {
	data: null,
	dataBooking: null,
	dataVisit: null,
	dataAll: [],
	dataRecommends: [],
	dataHotDeals: [],
	dataHistories: [],
	dataBookingList: [],
  isAddMore: false,
  nextKey: ''
};

export default function NursingHomeReducer(state = initialState, action) {

	switch (action.type) {
		case NURSING_HOME_BOOKING_LIST:
			return {
				...state,
				...action?.playload,
				dataBookingList: action?.playload?.isAddMore ? [...state.dataBookingList, ...action.playload.data] : action?.playload.data
			};
		case NURSING_HOME_LIST:
		case NURSING_HOME_LIST_RECOMMEND:
		case NURSING_HOME_LIST_HOT_DEAL:
		case NURSING_HOME_GET_BY_CODE:
		case NURSING_HOME_BOOKING:
		case NURSING_HOME_GET_HISTORY:
		case NURSING_HOME_BOOKING_GET_BY_CODE:
		case NURSING_HOME_BOOKING_UPDATE:
		case NURSING_HOME_BOOKING_CANCEL:
		case NURSING_HOME_VISIT:
			return { ...state, ...action.playload };
		case NURSING_HOME_BOOKING_CLEAR:
			return { ...state, dataBooking: null, isUpdate: undefined, isCancel: undefined, status: undefined };
		default:
			return { ...state };
	}
};