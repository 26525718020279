import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, INSURANCE_LIST, INSURANCE_CIGNA_CHECK_BENEFIT, INSURANCE_SSO_CHECK_BENEFIT, INSURANCE_MTL_GET_CONSENT, INSURANCE_MTL_CHECK_BENEFIT, INSURANCE_CLEAR_BENEFIT } from './types'
import { API_PARTNER_URL, API_PARTNER_ACCESS_TOKEN } from '../configs'

export const insuranceList = (appName) => async (dispatch) => {

	let url = `insurances?`;
	const appCode = localStorage.getItem('app-code');
	if(appCode){
		url += `&app=${appCode}`;
	}else{
		url += `&app=${appName}`;
	}
	dispatch({ type: IS_LOADING, status: true, names: { INSURANCE_LIST: true } });
	await axios.get(url).then(response => {
		dispatch({ type: INSURANCE_LIST, playload: { dataAll: response.data.data } });
		dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_LIST: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_LIST: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const insuraceCignaCheckBenefit = (birthDate, idCard, insurerCode = '', docCode = '') => async (dispatch) => {

	let url = `/insure/cigna/checkBenefit?birthDate=${birthDate}&passportNumber=${idCard}`;
	if (!_.isEmpty(insurerCode) && !_.isEmpty(docCode)) {
		url += `&doctor=${docCode}&insurerCode=${insurerCode}`;
	}

	dispatch({ type: IS_LOADING, status: true, names: { INSURANCE_CIGNA_CHECK_BENEFIT: true } });
	await axios.get(url).then(response => {
		const data = response.data.data;
		if (data.length > 0) {
			dispatch({ type: INSURANCE_CIGNA_CHECK_BENEFIT, playload: { dataBenefit: data, isCignaBenefit: true } });
		} else {
			dispatch({ type: INSURANCE_CIGNA_CHECK_BENEFIT, playload: { dataBenefit: {}, msgBenefit: '', isCignaBenefit: false } });
		}
		dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_CIGNA_CHECK_BENEFIT: false } });
	}).catch(error => {
		let msg = '';
		if (_.has(error.response, 'data') && error.response.data.length > 0 && _.has(error.response.data[0], 'msg')) {
			msg = error.response.data[0].msg;
		}
		dispatch({ type: INSURANCE_CIGNA_CHECK_BENEFIT, playload: { dataBenefit: {}, msgBenefit: msg, isCignaBenefit: false } });
		dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_CIGNA_CHECK_BENEFIT: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const insuraceSSOCheckBenefit = (idCard) => async (dispatch) => {

	let url = `/socialSecurity?idCardNumber=${idCard}`;

	let columns = ['birthDate', 'checkIdCard', 'firstName', 'lastName', 'idCardNumber', 'isAvailable', 'hospitals', 'gender', 'type'];
	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}

	dispatch({ type: IS_LOADING, status: true, names: { INSURANCE_SSO_CHECK_BENEFIT: true } });
	await axios.get(url).then(response => {
		const data = response.data.data;
		if (data.length > 0 && _.has(data[0], 'idCardNumber')) {
			dispatch({ type: INSURANCE_SSO_CHECK_BENEFIT, playload: { dataBenefit: data[0], isSSOBenefit: true } });
		} else {
			dispatch({ type: INSURANCE_SSO_CHECK_BENEFIT, playload: { dataBenefit: {}, isSSOBenefit: false } });
		}
		dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_SSO_CHECK_BENEFIT: false } });
	}).catch(error => {
		dispatch({ type: INSURANCE_SSO_CHECK_BENEFIT, playload: { dataBenefit: {}, isSSOBenefit: false } });
		dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_SSO_CHECK_BENEFIT: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const insuraceMTLGetConsent = (patCode) => async (dispatch) => {

	let url = `${API_PARTNER_URL}/v1/mtl/consent?patCode=${patCode}`;

	dispatch({ type: IS_LOADING, status: true, names: { INSURANCE_MTL_GET_CONSENT: true } });
	await axios.get(url, {
		headers: {
			Authorization: `Bearer ${API_PARTNER_ACCESS_TOKEN}`
		}
	}).then(response => {
		dispatch({ type: INSURANCE_MTL_GET_CONSENT, playload: { dataConsent: response.data.data } });
		dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_MTL_GET_CONSENT: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_MTL_GET_CONSENT: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}


export const insuraceMTLCheckBenefit = (patCode, refCode, insuranceCode, docCode) => async (dispatch) => {

	let url = `${API_PARTNER_URL}/v1/mtl/confirmConsent`;

	dispatch({ type: IS_LOADING, status: true, names: { INSURANCE_MTL_CHECK_BENEFIT: true } });
	await axios.post(url, { consentRefCode: refCode, patCode: patCode }, {
		headers: {
			Authorization: `Bearer ${API_PARTNER_ACCESS_TOKEN}`
		}
	}).then(async () => {
		url = `${API_PARTNER_URL}/v1/mtl/checkBenefit`;
		let params = {
			insurerCode: 'MTL',
			consentRefCode: refCode,
			patCode: patCode,
			insuranceNumber: insuranceCode,
			doctor: docCode
		};
		await axios.post(url, params, {
			headers: {
				Authorization: `Bearer ${API_PARTNER_ACCESS_TOKEN}`
			}
		}).then(response => {
			const data = response.data.data;
			if (_.has(data, 'Benefit') && data.Benefit.length > 0) {
				dispatch({ type: INSURANCE_MTL_CHECK_BENEFIT, playload: { dataBenefit: data.Benefit, isMTLBenefit: true } });
			} else {
				dispatch({ type: INSURANCE_MTL_CHECK_BENEFIT, playload: { dataBenefit: {}, msgBenefit: '', isMTLBenefit: false } });
			}
			dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_MTL_CHECK_BENEFIT: false } });
		}).catch(error => {
			dispatch({ type: INSURANCE_MTL_CHECK_BENEFIT, playload: { dataBenefit: {}, isMTLBenefit: false } });
			dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_MTL_CHECK_BENEFIT: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_MTL_CHECK_BENEFIT: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const insuraceClearBenefit = () => async (dispatch) => {
	dispatch({ type: INSURANCE_CLEAR_BENEFIT });
}