import { FEEDBACK_SEND } from '../actions/types';

const initialState = {
	data: {}
};

export default function FeedbackReducer(state = initialState, action) {

	switch (action.type) {
		case FEEDBACK_SEND:
			return { ...state, ...action.playload };
		default:
			return { ...state };
	}
}