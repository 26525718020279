import { lazy, Suspense, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { ToastContainer } from 'react-toastify';
import sign from 'jwt-encode';
// import TagManager from 'react-gtm-module'
// import { install } from 'ga-gtag';
import _ from "lodash";
import liff from '@line/liff';
import Bugsnag from '@bugsnag/js';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';

import routes from './routes';
import { DEFAULT_CREATE_BY_APP, COOKIE_SECURE } from './configs';
import { authValidateToken, authAccessToken, authSignOut, masterApps, masterDoctorPrefix, masterGroupSpecialties, hospitalList, masterLang, masterSymptom, masterOrgan, masterTagsAndEvents, insuranceList, masterLineOwnerCode, masterNursingHomePriceRange, masterNursingHomeRoomStyle, masterNursingHomeIllness, isLoading as dispatchIsLoading, getCompanyByCode, masterCampaigns } from './actions'
import Loading from './components/Loading';
import './App.scss';
import 'bootstrap';
import WhiteLabelConfig from './configs/whitelabel';
import { getFileS3 } from './helpers/utilities';

const SignalConnect = lazy(() => import('./SignalConnect'));
const InitialComponent = lazy(() => import('./components/InitialComponent'));
const AppLayout = lazy(() => import('./layouts/AppLayout'));
const VideoLayout = lazy(() => import('./layouts/VideoLayout'));
const BlankLayout = lazy(() => import('./layouts/BlankLayout'));
const Telemed = lazy(() => import('./views/pages/Telemed'));
const TelemedGuest = lazy(() => import('./views/pages/TelemedGuest'));
const TelemedPatient = lazy(() => import('./views/pages/TelemedPatient'));
const Authtoken = lazy(() => import('./views/pages/Authtoken'));
const ReviewDoctor = lazy(() => import('./views/pages/ReviewDoctor'));
const DrugOrder = lazy(() => import('./views/pages/DrugOrder'));
const NotFound = lazy(() => import('./views/errors/NotFound'));
let toastifyPosition = 'top-right';

const App = () => {
	const dispatch = useDispatch();
	const loading = useSelector(state => state.loading);
	const master = useSelector(state => state.master);
	const { search, pathname } = window.location;
	const [cookie, setCookie] = useCookies();
	const [createByApp] = useState(DEFAULT_CREATE_BY_APP);
	const [lineCode, setLineCode] = useState();
	const [campaign, setCampaign] = useState('');
	const [store, setStore] = useState('');
	const [urlParams, setUrlParams] = useState([]);
	const [checkCampaign, setCheckCampaign] = useState(false);
	const isLoading = () => {
		if (loading.status)
			return true;
		if (_.filter(_.values(loading.names), (name) => name).length > 0)
			return true;
		return false;
	}
	const isDev = process.env.REACT_APP_ENV !== 'production';

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const settingWhiteLabel = useCallback(data => {
		async function fetchSetData(data) {
			const theme = {
				companyCode: data.companyCode,
				payments: _.has(data.theme, 'payment') && !_.isEmpty(data.theme.payment) ? data.theme.payment : {},
				mainColor: data.theme.mainColor,
				subColor: data.theme.subColor,
				tertiaryColor: _.has(data, 'theme.tertiaryColor') ? data.theme.tertiaryColor : '#C3AB77',
				title: data.name,
				logo: _.has(data, 'theme.marketplace_logo') ? data.theme.marketplace_logo : '',
				appForCompany: _.has(data, 'appForCompany') ? data.appForCompany : {},
				webApplication: _.has(data.theme, 'webApplication') ? data.theme.webApplication : {},
				contacts: {
					lineAdd: _.has(data, 'webLineAdd') && !_.isEmpty(data.webLineAdd) ? data.webLineAdd : '',
					phoneNumber: _.has(data, 'webTelephone') && !_.isEmpty(data.webTelephone) ? data.webTelephone : ''
				},
				logoBrand: _.has(data, 'imageNew') ? data.imageNew : '',
			}
			if (!_.has(theme, 'payments.insurance')) {
				if (_.has(theme, 'payments.scb_qrpayment')) {
					theme.payments['insurance'] = theme.payments['scb_qrpayment'];
				} else if (_.has(theme, 'payments.scb_paywise')) {
					theme.payments['insurance'] = theme.payments['scb_paywise'];
				} else if (_.has(theme, 'payments.2c2p')) {
					theme.payments['insurance'] = theme.payments['2c2p'];
				}
			}
			dispatch({ type: 'WHITELABEL_SETTING_THEME', playload: { data: theme } });
			if (_.has(theme, 'title') && _.has(theme, 'mainColor')) {
				if (_.has(theme, 'logo.key')) {
					const logoBrandUrl = await getFileS3(theme.logo);
					if (document.getElementById("logo-brand")) {
						document.getElementById("logo-brand").src = logoBrandUrl;
						document.getElementById("logo-brand").alt = theme.title;
					}
					var link = document.querySelector("link[rel~='icon']");
					if (link) {
						link.href = logoBrandUrl;
					}
					var metaDescription = document.querySelector("meta[name~='description']");
					if (metaDescription) {
						metaDescription.content = theme.title;
					}
					var metaAuthor = document.querySelector("meta[name~='author']");
					if (metaAuthor) {
						metaAuthor.content = theme.title;
					}
					var metaKeyword = document.querySelector("meta[name~='keyword']");
					if (metaKeyword) {
						metaKeyword.content = theme.title;
					}
				}
				document.title = theme.title;
				const style = document.createElement("style");
				document.head.appendChild(style);
				let styleCss = `.navbar-expand-lg { background-color: ${theme.subColor}!important; }`;
				styleCss += `.download { background-color: ${theme.subColor}!important; }`;
				styleCss += `.footer { background-color: ${theme.mainColor}!important; }`;
				styleCss += `.btn-primary, .btn-primary.disabled:hover { background-color: ${theme.mainColor}!important; border-color: ${theme.mainColor}!important; }`;
				if (theme.subColor.toLowerCase() !== '#ffffff') {
					styleCss += `.btn-secondary, .btn-secondary.disabled:hover { background-color: ${theme.subColor} !important; border-color: ${theme.subColor}!important; }`;
				}
				styleCss += `.btn-outline-secondary, .btn-outline-secondary.disabled:hover { color: ${theme.mainColor} !important; border-color: ${theme.mainColor}!important; }`;
				styleCss += `.fix-font-size-color { color: ${theme.mainColor}!important; }`;
				styleCss += `.modal-custom .block-banner { background: linear-gradient(180deg, ${theme.mainColor} -58.21%, ${theme.subColor} 100%)!important; }`;
				styleCss += `.download { background-color: ${theme.subColor}!important; }`;
				styleCss += `.download .btn-secondary{ background-color: ${theme.mainColor} !important; border-color: ${theme.mainColor}!important; }`;
				styleCss += `.tab-personal .nav-item .active { background-color: ${theme.mainColor}!important; }`;
				styleCss += `.account .header { background-color: ${theme.mainColor}!important; }`;
				styleCss += `.tab-doctor-profile .nav-item .active { background-color: ${theme.mainColor}!important; }`;
				styleCss += `.flex-menubar-fixed { background-color: ${theme.mainColor}!important; }`;
				styleCss += `.offcanvas.canvas-menu { background-color: ${theme.mainColor}!important; }`;
				styleCss += `.canvas-menu .navbar { background-color: ${theme.mainColor}!important; }`;
				styleCss += `.d-menu-small .dropdown-menu .dropdown-item.active { background-color: ${theme.mainColor}!important; }`;
				styleCss += `section.footer .accordion-button { background-color: ${theme.mainColor}!important; }`;
				styleCss += `.tab-settings .nav-link.active { background-color: ${theme.mainColor}!important; }`;
				styleCss += `.canvas-chat .offcanvas-header { background: ${theme.mainColor}!important; }`;
				styleCss += `.list-assessment .assess-item { background: ${theme.mainColor}!important; }`;
				style.innerHTML = styleCss;
			}
		}
		fetchSetData(data);
	}, [dispatch]);

	useEffect(() => {
		if (/Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			toastifyPosition = 'top-center';
		}
		async function fetchData() {
			const currentDomain = window.location.hostname;
			const index = _.findIndex(WhiteLabelConfig.mapping, (o) => { return o.domainName === currentDomain; });
			if (index >= 0) {
				const response = await getCompanyByCode(WhiteLabelConfig.mapping[index].companyCode);
				if (_.has(response, 'data') && _.isArray(response.data) && response.data.length > 0) {
					dispatch(insuranceList(WhiteLabelConfig.mapping[index].appCode));
					settingWhiteLabel(response.data[0]);
					setCookie('companyCode', response.data[0].companyCode, { path: '/', secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
					localStorage.setItem('company-code', response.data[0].companyCode);
					setCookie('appCode', WhiteLabelConfig.mapping[index].appCode, { path: '/', secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
					localStorage.setItem('app-code', WhiteLabelConfig.mapping[index].appCode);
					dispatch({ type: 'WHITELABEL_SETTING', playload: { data: response.data[0] } });
				} else {
					dispatch(insuranceList(createByApp));
					if (localStorage.getItem('company-code')) {
						localStorage.removeItem('company-code');
					}
					if (localStorage.getItem('app-code')) {
						localStorage.removeItem('app-code');
					}
				}
			} else {
				if (localStorage.getItem('company-code')) {
					localStorage.removeItem('company-code');
				}
				if (localStorage.getItem('app-code')) {
					localStorage.removeItem('app-code');
				}
				dispatch(insuranceList(createByApp));
			}
		}
		dispatch(masterApps());
		dispatch(masterDoctorPrefix());
		dispatch(masterGroupSpecialties());
		dispatch(masterLang());
		dispatch(masterSymptom());
		dispatch(masterOrgan());
		dispatch(masterTagsAndEvents());
		dispatch(hospitalList());
		dispatch(masterLineOwnerCode());
		dispatch(masterNursingHomePriceRange());
		dispatch(masterNursingHomeRoomStyle());
		dispatch(masterNursingHomeIllness());
		dispatch(masterCampaigns());
		fetchData();
	}, [dispatch, createByApp, settingWhiteLabel, setCookie])

	useEffect(() => {
		let params = new URLSearchParams(search);
		if (_.has(cookie, 'accessToken')&&cookie.accessToken!==''&&cookie.accessToken!=='null') {
			dispatch({ type: 'IS_LOADING', status: true, names: { AUTH_VALIDATE_TOKEN: true } });
			authValidateToken(cookie.accessToken).then(response => {
				if (_.has(response, 'error')) {
					Bugsnag.notify({
						...response,
						action: 'App main auth check'
					});
					dispatch(authSignOut());
				} else {
					if (_.has(response, 'user.params')) {
						if (_.has(response.user.params, 'lineCode') && _.has(response.user.params, 'lineUserId')) {
							if (!_.has(cookie, 'lineCode')) {
								setCookie('lineCode', response.user.params.lineCode, { path: '/', secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
							}
							if (!_.has(cookie, 'lineUserId')) {
								setCookie('lineUserId', response.user.params.lineUserId, { path: '/', secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
							}
						}
					}
					dispatch(authAccessToken(response, cookie))
				}
				dispatch({ type: 'IS_LOADING', status: false, names: { AUTH_VALIDATE_TOKEN: false } });
			}).catch(() => {
				dispatch(authSignOut())
				dispatch({ type: 'IS_LOADING', status: false, names: { AUTH_VALIDATE_TOKEN: false } });
			})
		} else {
			let lineCodeCheck = '';
			params.forEach((pValue, pKey) => {
				if (pKey === 'lineCode') {
					lineCodeCheck = pValue;
				}
			});
			if ('' !== lineCodeCheck) {
				setLineCode(lineCodeCheck);
			}
		}
		let campaign = '';
		let store = '';
		let paramUrl = [];
		params.forEach((pValue, pKey) => {
			if (pKey === 'campaign') {
				paramUrl.push({ key: pKey, value: pValue });
				campaign = pValue;
			}
			if (pKey === 'store') {
				store = pValue;
			}
		});
		setUrlParams(paramUrl);
		if ('' !== campaign) {
			setCampaign(campaign);
		}
		if ('' !== store) {
			setStore(store);
		}
	}, [dispatch, cookie, search, setCookie]);

	useEffect(() => {
		if (!checkCampaign) {
			if (campaign !== '' && urlParams.length > 0) {
				setCookie('campaign', campaign, { path: '/', secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
			}
			if (store !== '' && urlParams.length > 0) {
				setCookie('store', store, { path: '/', secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
			}
			if (campaign !== '' && urlParams.length > 0) {
				setCheckCampaign(true);
				setCookie('campaign_time', moment().format('YYYY-MM-DD HH:mm:ss'), { path: '/', secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
			}
		}
	}, [cookie, setCookie, campaign, store, urlParams, checkCampaign]);

	useEffect(() => {
		if ('' !== lineCode && _.isArray(master.lineOwnerCode) && master.lineOwnerCode.length > 0 && !_.has(cookie, 'accessToken')) {
			let lines = master.lineOwnerCode.filter((o) => {
				return o.CODE === lineCode;
			});
			if (lines.length > 0 && _.has(lines[0], 'LINE_LIFF_ID_VERYFY') && _.has(lines[0], 'LIFF_ID_DOCTOR_VERYFY') && _.has(lines[0], 'LIFF_ID_HEALTH_VERYFY') && _.has(lines[0], 'LIFF_ID_BOOKING_VERYFY')) {
				let liffIdCode = '';
				let lineDoctor = window.location.pathname.split('/doctor-profile/');
				let docCode = '';
				if (lineDoctor.length === 2) {
					docCode = lineDoctor[1];
				}
				if ('/doctors' === window.location.pathname) {
					liffIdCode = lines[0].LINE_LIFF_ID_VERYFY;
				} else if ('/account' === window.location.pathname && window.location.search.search('health') >= 0) {
					liffIdCode = lines[0].LIFF_ID_HEALTH_VERYFY;
				} else if ('/account' === window.location.pathname && window.location.search.search('history') >= 0) {
					liffIdCode = lines[0].LIFF_ID_BOOKING_VERYFY;
				} else if (window.location.pathname.search('/doctor-profile/') >= 0 && docCode !== '') {
					let docs = lines[0].LIFF_ID_DOCTOR_VERYFY.filter((o) => {
						return o.docCode === docCode;
					});
					if (docs.length > 0) {
						liffIdCode = docs[0].LIFF_ID;
					}
				}
				if (liffIdCode !== '') {
					dispatch(dispatchIsLoading(true));
					liff.init({
						liffId: liffIdCode,
					}).then(() => {
						if (liff.isLoggedIn()) {
							liff.getProfile().then((profile) => {
								const lineOaToken = sign({
									userId: profile.userId,
									displayName: profile.displayName,
									statusMessage: profile.statusMessage,
									pictureUrl: profile.pictureUrl,
									email: liff.getDecodedIDToken().email,
									lineCode: lineCode
								}, 'LINE_SECRET');
								dispatch(dispatchIsLoading(false));
								if (!_.has(cookie, 'lineCode')) {
									setCookie('lineCode', lineCode, { path: '/', secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
								}
								if (!_.has(cookie, 'lineUserId')) {
									setCookie('lineUserId', profile.userId, { path: '/', secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
								}
								if (!_.has(cookie, 'accessToken')) {
									setCookie('accessToken', lineOaToken, { path: '/', secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
								}
							}).catch(() => {
								dispatch(dispatchIsLoading(false));
							});
						} else {
							dispatch(dispatchIsLoading(false));
						}
					}).catch(() => {
						dispatch(dispatchIsLoading(false));
					});
				}
			}
		}
	}, [dispatch, master.lineOwnerCode, lineCode, setCookie, cookie])

	useEffect(() => {
		const keyConsent = `${createByApp}_cookie_consent`;
		if (_.has(cookie, keyConsent) && _.has(cookie[keyConsent], 'analytics') && cookie[keyConsent].analytics) {
			// install('UA-199244681-1');
			// TagManager.initialize({ gtmId: 'GTM-PH5QDP3' });
		}
	}, [cookie, createByApp])

	useEffect(() => {
		if (!_.has(cookie, 'uuid') || cookie.uuid===''||cookie.uuid==='null') {
			const oneDayFromNow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
			setCookie('uuid',(Math.random() * (10000000 - 1000000) + 1000000), { path: '/', expires: oneDayFromNow, secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
		}
	}, [cookie, setCookie]);

	return (
		<>
			{isDev && (
				<Helmet>
					<meta name="robots" content="noindex, nofollow" />
					<title>Thailand Nursing Home</title>
				</Helmet>
			)}
			{(isLoading()) && (<Loading message={loading.message} />)}
			<ToastContainer hideProgressBar draggable={false} position={toastifyPosition} />
			<SignalConnect />
			<BrowserRouter>
				<InitialComponent />
				<Routes>
					<Route path='/review/:token' element={<Suspense fallback={null}><BlankLayout /></Suspense>}>
						<Route index element={<ReviewDoctor />} />
					</Route>
					<Route path='/telemed/:token' element={<Suspense fallback={null}><VideoLayout /></Suspense>}>
						<Route index element={<Telemed />} />
					</Route>
					<Route path='/telemed/guest/:guestCode/:token' element={<Suspense fallback={null}><VideoLayout /></Suspense>}>
						<Route index element={<TelemedGuest />} />
					</Route>
					<Route path='/telemed/patient/:token' element={<Suspense fallback={null}><BlankLayout /></Suspense>}>
						<Route index element={<TelemedPatient />} />
					</Route>
					<Route path='/authtoken/:token' element={<Suspense fallback={null}><BlankLayout /></Suspense>}>
						<Route index element={<Authtoken />} />
					</Route>
					<Route path='/drug-order/:token' element={<Suspense fallback={null}><BlankLayout navbar /></Suspense>}>
						<Route index element={<DrugOrder />} />
					</Route>
					<Route path="/" element={<Suspense fallback={null}><AppLayout /></Suspense>}>
						{routes.map(({ path, Element }, key) => (
							<Route
								key={key}
								path={path}
								element={<Element />}
							/>
						))}
						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;