const AWSConfig = {
	config: {
		aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDT_POOL_ID,
		aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
		aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
		aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
		aws_user_files_s3_bucket: process.env.REACT_APP_AWS_BUCKET,
		aws_user_files_s3_bucket_region: process.env.REACT_APP_COGNITO_REGION,
	}
}
export default AWSConfig;